import netlifyIdentity from "netlify-identity-widget";

netlifyIdentity.init({
  //   container: "#netlify-modal", // defaults to document.body
  locale: "de", // defaults to 'en'
  APIUrl: "https://admin.smartificate.de/.netlify/identity",
});

const netlifyAuth = {
  initialize(callback: (user: any) => void) {
    netlifyIdentity.on("init", (user: any) => {
      callback(user);
    });
    netlifyIdentity.on("error", (err: any) => console.error("Error", err));
    netlifyIdentity.init();
    // netlifyIdentity.open();
  },
  authenticate(callback: (user: any) => void) {
    netlifyIdentity.open();
    netlifyIdentity.on("login", (user: any) => {
      callback(user);
      netlifyIdentity.close();
      netlifyIdentity.refresh().then((_: any) => {});
    });
  },

  signout(callback: () => void) {
    netlifyIdentity.logout();
    netlifyIdentity.on("logout", () => {
      callback();
    });
  },
};

export default netlifyAuth;
