import React, { createContext, useEffect, useRef, useState } from "react";
import "./App.css";
import ImageArea from "./pages/imageArea/ImageArea";
import { Redirect, Router } from "@reach/router";
import netlifyAuth from "./state/netlifyAuth";
import { RootState, useAppDispatch, useAppSelector } from "./state/reducers";
import { setAuthState, checkIsLoggedIn, getEmail } from "./state/authSlice";
import PayoutHistoryAdmin from "./pages/PayoutHistoryAdmin";
import ManualInvoice from "./pages/ManualInvoice";
import OverwriteInvoice from "./pages/OverwriteInvoice";
export const UserContext = createContext(null);

export const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const login = () => {
    netlifyAuth.authenticate((user) => {
      dispatch(
        setAuthState({ token: user.token.access_token, email: user.email })
      );
    });
  };
  return (
    <div className="mt-32 max-w-md bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 mx-auto">
      <button
        className="mx-auto block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        type="button"
        onClick={login}
      >
        Login
      </button>
    </div>
  );
};

function App() {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state: RootState) => state);
  const isLoggedIn = checkIsLoggedIn(state);
  const mayEmail = getEmail(state);

  const logout = () => {
    netlifyAuth.signout(() => {
      dispatch(setAuthState("LoggedOut"));
    });
  };

  return (
    <>
      {isLoggedIn ? (
        <>
          <div className="flex flex-row fixed top-0 right-0 w-80 z-10">
            {mayEmail !== null && mayEmail}
            <button
              className="mx-auto block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
              onClick={logout}
            >
              logout
            </button>
          </div>
          <Router>
            <Redirect from="/login" to="/" noThrow={true} />
            <Redirect from="/index.html" to="/" noThrow={true} />
            <Redirect from="/signed" to="/" noThrow={true} />
            <ImageArea path="/" />
            <PayoutHistoryAdmin path="/PayoutHistoryAdmin" />
            <ManualInvoice path="/ManualInvoice" />
            <OverwriteInvoice path="/OverwriteInvoice" />
          </Router>
        </>
      ) : (
        <Login />
      )}
    </>
  );
}

export default App;
