import React, { useEffect, useRef, useState } from "react";
import {
  colorFromConfidence,
  drawSquareBasedOnVertices,
  getAbsCursorPosition,
  getCursorPosition,
  getRelativeCursorPosition,
  inferRotationOfOcrJson,
  isPointInsideVertices,
  processGoogleAPIJson,
} from "./util";
import _ from "lodash";

const confThreshold = 0.85;
const clickThreshold = 0.3;

export const ImageOcr = ({
  url,
  json,
  onSelect,
  canvasRef,
  rotate,
  showOcr,
}: {
  url: string;
  json?: any;
  onSelect: (word: string) => void;
  canvasRef: React.RefObject<HTMLCanvasElement>;
  rotate: 90 | 180 | 270 | 0;
  showOcr: boolean;
}) => {
  const firstRender = useRef(true);
  const [error, setError] = useState<boolean>(false);
  const { blocks, allWords } =
    !showOcr || _.isEmpty(json) || json.error
      ? { blocks: [], allWords: [] }
      : processGoogleAPIJson(json);
  const imgRotations = rotate / 90;
  const inferredRotations = _.isEmpty(allWords)
    ? 0
    : inferRotationOfOcrJson(allWords);
  // This ensures that the OCR is always rotated 0 degrees
  const ocrRotations = (360 - inferredRotations) % 360;
  const numOcrRotations = inferredRotations / 90;
  // console.log("inferred:", inferredRotations, numOcrRotations);
  // const totalRotations = (inferredRotations + imgRotations) % 360;

  useEffect(() => {
    setError(false);
    const canvas = canvasRef!.current!;
    const img = new Image();
    const ctx = canvas.getContext("2d")!;

    if (firstRender) {
      ctx.save();
      firstRender.current = false;
    } else {
      ctx.restore();
      ctx.save();
    }
    console.log(json);

    function onClick(e: MouseEvent) {
      // ctx.setTransform(1, 0, 0, 1, 0, 0);
      // scaler between image size and displayed image size
      const scaler =
        imgRotations % 2 === 0
          ? img.naturalWidth / canvas.offsetWidth
          : img.naturalHeight / canvas.offsetWidth;

      let { x, y } = getAbsCursorPosition(canvas, scaler, e);
      const xOld = x;
      const yOld = y;
      const xMax = canvas.width;
      const yMax = canvas.height;
      let { xPercent, yPercent } = getRelativeCursorPosition(canvas, scaler, e);
      console.log(xPercent, yPercent);
      // if (ocrRotations > 0) {
      //   for (let i = 0; i < 4 - ocrRotations; i++) {
      //     const temp = x;
      //     x = (i % 2 ? yMax : xMax) - y;
      //     y = temp;
      //   }
      // }
      // x 1 x x x    5x4 -> (1,0)   x x x x   4x5 -> (4,1)
      // x x x x x           (3,2)   x x x 1          (1,3)
      // x x x 2 x                   x x x x
      // x x x x x                   x 2 x x
      //                             x x x x

      if (numOcrRotations > 0) {
        for (let i = 0; i < 4 - numOcrRotations; i++) {
          const temp = yPercent;
          yPercent = 1 - xPercent;
          xPercent = temp;
        }
        ctx.fillStyle = "#00FF00";
        ctx.fillRect(xOld, yOld, 20, 20);

        if (imgRotations % 2 === 0) {
          x = xPercent * canvas.width;
          y = yPercent * canvas.height;
        } else {
          x = xPercent * canvas.height;
          y = yPercent * canvas.width;
        }
      }

      // if (ocrRotations % 2 === 1) {
      //   // const scale =
      //   //   canvas.width > canvas.height
      //   //     ? canvas.width / canvas.height
      //   //     : canvas.height / canvas.width;
      //   pos.x *= scaler;
      //   pos.y *= scaler;
      // }

      ctx.fillStyle = "#FF0000";
      ctx.fillRect(x, y, 20, 20);
      for (const block of blocks) {
        if (isPointInsideVertices({ x, y }, block.boundingPoly.vertices)) {
          const minConf = Math.min(
            ...allWords[blocks.indexOf(block)].symbols.map(
              (s: any) => s.confidence
            )
          );
          if (minConf > clickThreshold) {
          }
          onSelect(block.description);
          // console.log(block.description);
          console.log(allWords[blocks.indexOf(block)].confidence);
        }
      }
    }

    img.src = url;
    img.onload = function () {
      console.log("rotate", rotate);
      const shiftWidthHeight = rotate === 90 || rotate === 270;
      canvas.width = shiftWidthHeight ? img.naturalHeight : img.naturalWidth;
      canvas.height = shiftWidthHeight ? img.naturalWidth : img.naturalHeight;
      // reset canvas to empty
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.save();

      let offset = [canvas.width / 2, canvas.height / 2];

      // Shift to center so we can rotate around it
      ctx.translate(offset[0], offset[1]);
      // after translation width and height of offset shift, if rotate is 90 or 270
      if (shiftWidthHeight) offset = [offset[1], offset[0]];

      if (rotate !== 0) {
        ctx.rotate((Math.PI / 180) * rotate);
      }
      ctx.drawImage(img, -offset[0], -offset[1]);
      ctx.restore();
      if (showOcr) {
        ctx.save();
        let offset = [canvas.width / 2, canvas.height / 2];
        ctx.translate(offset[0], offset[1]);
        const shiftOffset = ocrRotations === 90 || ocrRotations === 270;
        if (shiftOffset) offset = [offset[1], offset[0]];
        if (ocrRotations !== 0) {
          ctx.rotate((Math.PI / 180) * ocrRotations);
        }

        for (const block of blocks) {
          const matchingWord = allWords[blocks.indexOf(block)];
          const minConf = Math.min(
            ...matchingWord.symbols.map((s: any) => s.confidence)
          );
          const vertices = block.boundingPoly.vertices;
          if (minConf > confThreshold) {
            // const color = colorFromConfidence(minConf);
            // drawSquareBasedOnVertices(ctx, vertices, color);
            drawSquareBasedOnVertices(ctx, vertices, "#000", [
              -offset[0],
              -offset[1],
            ]);
          }
        }
        ctx.restore();
      }
    };
    img.onerror = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      setError(true);
    };

    canvas.addEventListener("click", onClick);
    return () => {
      canvas.removeEventListener("click", onClick);
    };
  }, [
    url,
    json,
    onSelect,
    canvasRef,
    rotate,
    showOcr,
    imgRotations,
    ocrRotations,
    numOcrRotations,
  ]);

  return (
    <>
      {error
        ? "Error loading the image. Please try again by using the button right to the DealId. If the error happens again on the same DealId, press the complicated button."
        : ""}
      <canvas ref={canvasRef} className="w-full h-auto border"></canvas>
    </>
  );
};
