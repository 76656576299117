import { PayoutDetails } from "./frontendTypes";

export type DealData = {
  userDealCount?: number;
  remainingDeals?: number;
  dealId: string;
  dealStage: string;
  firstName: string;
  email: string;
  lastName: string;
  company: string;
  companyVerified?: boolean;
  brand: string;
  rate: number;
  remcontractyears: number;
  bonus1Name: string;
  bonus1Value: string;
  bonus2Name: string;
  bonus2Value: string;
  whitelabelPartner: string;
  regCertFiles: RegCertFile[];
  regCertRegPlate: string;
  regCertLastName: string;
  regCertFirstName: string;
  regCertIssueDate: string;
  regCertCarCategory: string;
  regCertFIN: string;
  regCertFINPZ: string;
};
export type RegCertFile = {
  signedUrl: string;
  fileName: string;
  ocrJson?: any;
  ocrUrl?: any;
};

export enum DealStage {
  Registriert = "19148754",
  Fahrzeugschein = "19148755",
  Signed = "19148756",
  SignedComplicated = "36256204",
  Verified = "19148759",
  ChangesRequested = "19148757",
  ChangesToDo = "19931324",
  Lost = "19148760",
  UBAKompliziert = "30180340",
  UBAReady = "31940287",
  UBARequested = "36256205",
  UBAConfirmed = "46094546",
  Payout = "55948219",
  UBARejected = "46094547",
}
export const changesRequiredTypes = [
  "Different name",
  "Car not qualified",
  "Multiple certificates",
  "Verify company",
  "RegCert cut",
  "RegCert back missing",
  "RegCert front missing",
  "RegCert missing (got Fahrzeugbrief)",
  "RegCert everything missing",
  "RegCert blurry",
  "RegCert Not folded out",
  "remcontractyearsEmpty",
  "FIN duplicate error",
  "Other",
  "Error in tool",
  "Too many timeouts",
] as const;
export type ChangesRequiredType = typeof changesRequiredTypes[number];

export type ApiPayoutManualInvoice = {
  contactId: string;
  invoiceItems: {
    description: string;
    amountNet: number;
  }[];
};

export type ApiPayoutOverwriteInvoice = {
  contactId: string;
  oldInvoiceNumber: string;
  newInvoiceBillingType:
    | "TextCorrection"
    | "OpenPayout"
    | "OpenPayoutAfterRefund"
    | "Cancelation";
  dealDetails: PayoutDetails[];
};
