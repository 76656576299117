import React, { useState } from "react";
import {
  FieldValues,
  FormState,
  RegisterOptions,
  SubmitHandler,
  UnpackNestedValue,
  useForm,
  UseFormRegister,
} from "react-hook-form";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useSaveVerifiedDealMutation } from "../../state/adminApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage } from "@hookform/error-message";
import { ConfirmationPopup } from "./ConfirmationPopup";
import { getFinPruefziffer } from "../../helpers/finPruefziffer";
import { parseInt } from "lodash";
import { confirmAlert } from "react-confirm-alert";
import { Popup } from "../Popup/Popup";
import { DealData, RegCertFile } from "../../state/types";

type SkipChecks =
  | "regCert2"
  | "FINPZ"
  | "regCertsIdentical"
  | "samePerson"
  | "viewedAllImages"
  | "PZEmpty";
declare type SubmitHandlerSkippable<TFieldValues extends FieldValues> = (
  data: UnpackNestedValue<TFieldValues>,
  event?: React.BaseSyntheticEvent,
  skipCheck?: SkipChecks[]
) => any | Promise<any>;
export type FormFields = {
  regCertImage1: string;
  regCertImage2: string;
  regCertRegPlate: string;
  regCertLastName: string;
  regCertFirstName: string;
  regCertStreet: string;
  regCertZIP: number;
  regCertCity: string;
  regCertIssueDate: string;
  regCertCarCategory:
    | "M1"
    | "M1G"
    | "M2"
    | "M3"
    | "N1"
    | "N2"
    | "N3"
    | "L1e"
    | "L1e-A"
    | "L1e-B"
    | "L2e"
    | "L2e-P"
    | "L3e"
    | "L3e-A1"
    | "L3e-A2"
    | "L3e-A3"
    | "L3e-A1E"
    | "L4e"
    | "L5e"
    | "L6e"
    | "L6e-BP"
    | "L7e"
    | "L7e-CP"
    | "L7e-B2"
    | "01"
    | "10"
    | "18"
    | "31"
    | "88"
    | "26"
    | "89";
  regCertFIN: string;
  regCertFINPZ: string;
  // RegCertHSNTSNPZ: string;
  antrieb: "antrieb";
};
export const formFieldNames: (keyof FormFields)[] = [
  "regCertFIN",
  "regCertFINPZ",
  // "RegCertHSNTSNPZ",
  "regCertRegPlate",
  "regCertLastName",
  "regCertFirstName",
  "regCertStreet",
  "regCertZIP",
  "regCertCity",
  "regCertIssueDate",
  "regCertCarCategory",
  "antrieb",
];

export const DealDataForm: React.FC<{
  ocrSelectedVal: string;
  dealData: DealData;
  imageIdx: number;
  numOfPageChanges: number;
  getNetDeal: (dealId?: string | undefined) => void;
}> = ({ ocrSelectedVal, dealData, imageIdx, getNetDeal, numOfPageChanges }) => {
  const [addSpace, setAddSpace] = useState(true);
  const [submitData, setSubmitData] = useState<
    [DealData, string, boolean] | null
  >();
  const [focussedField, setFocussedField] =
    useState<keyof FormFields>("regCertFIN");

  const formRefs = React.useRef<{
    [key: string]: HTMLInputElement | null;
  }>({});

  const [
    _submitVerifiedDeal,
    { isLoading: isMutLoading, isError: isMutError, error: mutError },
  ] = useSaveVerifiedDealMutation();
  const submitVerifiedDeal = (deal: DealData) => {
    _submitVerifiedDeal(deal)
      .unwrap()
      .then(() => getNetDeal());
  };

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<FormFields>({
    criteriaMode: "all",
  });
  React.useEffect(() => {
    console.log("resetting form");
    reset({
      ...dealData,
      // regCertZIP: dealData.regCertZIP
      //   ? parseInt(dealData.regCertZIP)
      // : undefined,
      // @ts-ignore
      regCertCarCategory: dealData.regCertCarCategory,
    });

    setFocussedField("regCertFIN");
  }, [dealData, reset]);

  React.useEffect(() => {
    let newOcrString = ocrSelectedVal;

    console.log(newOcrString);
    newOcrString = newOcrString.replaceAll("м", "M"); // cyrillic M...
    newOcrString = newOcrString.replaceAll("М", "M"); // weird M...
    console.log(newOcrString);

    if (focussedField === "regCertFIN") {
      newOcrString = newOcrString.replaceAll("O", "0");
      newOcrString = newOcrString.replaceAll("I", "1");
      newOcrString = newOcrString.replaceAll("Q", "0");
    }
    if (
      focussedField === "antrieb" &&
      newOcrString.toLowerCase().replaceAll("o", "0").includes("0004")
    ) {
      newOcrString = "0004";
    }
    let newValue = "";
    const maySpace =
      addSpace && !(getValues()[focussedField] + "").endsWith(" ") ? " " : "";
    if (getValues()[focussedField])
      newValue = getValues()[focussedField] + maySpace;
    newValue += newOcrString;
    setValue(focussedField, newValue.trim());
    if (formRefs.current[focussedField] !== null)
      // @ts-ignore
      formRefs.current[focussedField].focus();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ocrSelectedVal]);

  const setFilenameToField = (internalName: keyof FormFields) => {
    setValue(internalName, dealData.regCertFiles[imageIdx].fileName);
  };

  const keyDownEvent = React.useCallback(
    (event) => {
      if (event.keyCode === 9) {
        event.preventDefault();
        //debugging
        if (focussedField === "regCertFIN") {
          console.log(getFinPruefziffer(getValues()[focussedField]));
        }

        //debugging end
        const nextField =
          formFieldNames.indexOf(focussedField) === formFieldNames.length - 1
            ? 0
            : formFieldNames.indexOf(focussedField) + 1;
        setFocussedField(formFieldNames[nextField]);

        if (formRefs.current[formFieldNames[nextField]] !== null)
          // @ts-ignore
          formRefs.current[formFieldNames[nextField]].focus();
      } else if (event.keyCode === 16) {
        setAddSpace(false);
      } else if (event.keyCode === 13) {
        // enter
        event.preventDefault();
        console.log("Enter pressed");
        handleSubmit(onSubmit)();
      }
    },
    [focussedField]
  );
  const keyUpEvent = React.useCallback((event) => {
    if (event.keyCode === 16) {
      setAddSpace(true);
    }
  }, []);

  React.useEffect(() => {
    document.addEventListener("keydown", keyDownEvent, false);
    return () => {
      document.removeEventListener("keydown", keyDownEvent, false);
    };
  }, [keyDownEvent]);
  React.useEffect(() => {
    document.addEventListener("keyup", keyUpEvent, false);
    return () => {
      document.removeEventListener("keyup", keyUpEvent, false);
    };
  }, [keyUpEvent]);

  let formField = ({
    displayName,
    internalName,
    value,
    defaultValue,
    disabled,
    fieldOptions,
    className,
  }: {
    displayName: string;
    internalName: keyof FormFields;
    value?: string;
    defaultValue?: string;
    disabled?: boolean;
    fieldOptions?: RegisterOptions<FormFields>;
    className?: string;
  }) => {
    if (!fieldOptions) fieldOptions = { required: "required" };
    let { ref: normalRef, ...rest } = register(internalName, fieldOptions);

    return (
      <div
        className={"form-control " + className}
        onClick={() => setFocussedField(internalName)}
        onFocus={() => setFocussedField(internalName)}
      >
        <label className="label my-0">
          <span className="label-text font-bold">{displayName}</span>
        </label>
        <input
          autoComplete="off"
          disabled={disabled ? disabled : false}
          className={`w-full input input-primary input-transparent text-lg px-2 ${
            focussedField === internalName ? "border-4 border-brand-yellow" : ""
          }`}
          ref={(e) => {
            normalRef(e);
            formRefs.current[internalName] = e;
          }}
          {...rest}
          value={value ? value : undefined}
          defaultValue={defaultValue ? defaultValue : undefined}
          // placeholder={displayName}
        />
        <ErrorMessage
          errors={errors}
          name={internalName}
          render={({ messages }) =>
            messages &&
            Object.entries(messages).map(([type, message]) => (
              <p className="text-md font-bold text-red-600" key={type}>
                {message}
              </p>
            ))
          }
        />
      </div>
    );
  };

  const onSubmit: SubmitHandlerSkippable<FormFields> = (
    data,
    event,
    skipCheck
  ) => {
    const regCertFiles: RegCertFile[] = [
      { signedUrl: "", fileName: data.regCertImage1 },
    ];
    if (data.regCertImage2)
      regCertFiles.push({ signedUrl: "", fileName: data.regCertImage2 });

    const newSubmitData = {
      ...dealData,
      ...data,
      // regCertZIP: data.regCertZIP.toString(),
      regCertFiles: regCertFiles as RegCertFile[],
    };

    console.log(
      getFinPruefziffer(data.regCertFIN),
      data.regCertFINPZ,
      parseInt(data.regCertFINPZ),
      getFinPruefziffer(data.regCertFIN) !== parseInt(data.regCertFINPZ)
    );

    if (!data.regCertImage2 && !(skipCheck && skipCheck.includes("regCert2"))) {
      const newSkipCheck: SkipChecks[] = skipCheck ? skipCheck : [];
      newSkipCheck.push("regCert2");
      confirmAlert({
        customUI: ({ onClose }) => (
          <Popup
            confirm={{
              text: "Submit",
              event: () => {
                onClose();
                onSubmit(data, event, newSkipCheck);
              },
            }}
            abort={{ text: "Cancel", event: onClose }}
            content={
              <>
                The field for the second page of the registration-certificate is
                empty. Are you sure that the single image includes both the
                front and the back of the certificate?
              </>
            }
          />
        ),
      });
    } else if (
      getFinPruefziffer(data.regCertFIN) !== false &&
      data.regCertFINPZ &&
      // isNumeric(data.regCertFINPZ) &&
      getFinPruefziffer(data.regCertFIN) !== parseInt(data.regCertFINPZ) &&
      !(skipCheck && skipCheck.includes("FINPZ"))
    ) {
      console.log(
        "calculated pruefziffer:",
        getFinPruefziffer(data.regCertFIN)
      );
      const newSkipCheck: SkipChecks[] = skipCheck ? skipCheck : [];
      newSkipCheck.push("FINPZ");
      confirmAlert({
        customUI: ({ onClose }) => (
          <Popup
            confirm={{
              text: "Submit",
              event: () => {
                onClose();
                onSubmit(data, event, newSkipCheck);
              },
            }}
            abort={{ text: "Cancel", event: onClose }}
            content={
              <>
                The control-number of the FIN seems to not fit the FIN. Please
                double check. Calcualted control-number:
                {getFinPruefziffer(data.regCertFIN)} - Are you very sure both
                are correct?
              </>
            }
          />
        ),
      });
    } else if (
      ((data.regCertFirstName &&
        !data.regCertFirstName
          .toLowerCase()
          .includes(dealData.firstName.toLowerCase())) ||
        (dealData.company &&
          !data.regCertLastName
            .toLowerCase()
            .includes(dealData.company.toLowerCase()))) &&
      !(skipCheck && skipCheck.includes("samePerson"))
    ) {
      const newSkipCheck: SkipChecks[] = skipCheck ? skipCheck : [];
      newSkipCheck.push("samePerson");
      confirmAlert({
        customUI: ({ onClose }) => (
          <Popup
            confirm={{
              text: "Submit",
              event: () => {
                onClose();
                onSubmit(data, event, newSkipCheck);
              },
            }}
            abort={{ text: "Cancel", event: onClose }}
            content={
              <>
                The name / companyname of the Hubspot Account, and the name in
                the registration certificate seem to be not the same person.
                Have you checked this? Please double check before skipping this
                message.
              </>
            }
          />
        ),
      });
    } else if (
      !data.regCertFINPZ &&
      !(skipCheck && skipCheck.includes("PZEmpty"))
    ) {
      const newSkipCheck: SkipChecks[] = skipCheck ? skipCheck : [];
      newSkipCheck.push("PZEmpty");
      confirmAlert({
        customUI: ({ onClose }) => (
          <Popup
            confirm={{
              text: "Submit",
              event: () => {
                onClose();
                onSubmit(data, event, newSkipCheck);
              },
            }}
            abort={{ text: "Cancel", event: onClose }}
            content={
              <>
                Warning! This registration certificate does not have a check
                digit. Have you checked the FIN carefully for correctness?
              </>
            }
          />
        ),
      });
    } else if (
      dealData.regCertFiles.length > numOfPageChanges + 1 &&
      !(skipCheck && skipCheck.includes("viewedAllImages"))
    ) {
      const newSkipCheck: SkipChecks[] = skipCheck ? skipCheck : [];
      newSkipCheck.push("viewedAllImages");
      confirmAlert({
        customUI: ({ onClose }) => (
          <Popup
            confirm={{
              text: "Submit",
              event: () => {
                onClose();
                onSubmit(data, event, newSkipCheck);
              },
            }}
            abort={{ text: "Cancel", event: onClose }}
            content={
              <>
                Warning! Have you checked the back of the certificate, and
                wether multiple certificates might be in the files?
              </>
            }
          />
        ),
      });
    } else if (
      data.regCertImage1 === data.regCertImage2 &&
      !(skipCheck && skipCheck.includes("regCertsIdentical"))
    ) {
      confirmAlert({
        customUI: ({ onClose }) => (
          <Popup
            abort={{ text: "Cancel", event: onClose }}
            content={<>Error. Both filenames are identical!</>}
          />
        ),
      });
    }

    // else if (
    //   dealData.firstName !== data.regCertFirstName ||
    //   dealData.lastName !== data.regCertLastName
    // )
    //   setSubmitData([
    //     newSubmitData,
    //     "The Name of the certificate is not identical to the name of the HubSpot account. Are you sure that this is correct?",
    //     false,
    //   ]);
    else {
      console.log("Passed checks, submitting");
      submitVerifiedDeal(newSubmitData);
    }
  };

  return (
    <div className="mx-0 w-84 flex-none h-screen">
      {submitData && (
        <ConfirmationPopup
          confirmationQuestion={submitData[1]}
          ignorable={submitData[2]} // if the error can be ignored
          onConfirm={() => {
            submitVerifiedDeal(submitData[0]);
            setSubmitData(undefined);
          }}
          onAbort={() => {
            setSubmitData(undefined);
          }}
        />
      )}
      <div className="fixed right-1 top-12 w-80 h-full overflow-y-scroll pb-16 px-1 shadow-md rounded-md border border-primary">
        <form onSubmit={handleSubmit(onSubmit)} className="" autoComplete="off">
          <div className="flex items-end">
            {dealData.regCertFiles.length > 2 && (
              <button
                type="button"
                className="btn btn-primary px-1 mr-1"
                onClick={() => setFilenameToField("regCertImage1")}
              >
                <FontAwesomeIcon icon={faArrowRight} size="lg" />
              </button>
            )}
            {formField({
              displayName: "Frontpage",
              internalName: "regCertImage1",
              defaultValue:
                dealData.regCertFiles.length < 3
                  ? dealData.regCertFiles[0].fileName
                  : undefined,
              // disabled: true,
            })}
          </div>
          <div className="flex items-end">
            {dealData.regCertFiles.length > 2 && (
              <button
                type="button"
                className="btn btn-primary px-1 mr-1"
                onClick={() => setFilenameToField("regCertImage2")}
              >
                <FontAwesomeIcon icon={faArrowRight} size="lg" />
              </button>
            )}
            {formField({
              displayName: "Backpage (if not in same file)",
              internalName: "regCertImage2",
              defaultValue:
                dealData.regCertFiles.length === 2
                  ? dealData.regCertFiles[1].fileName
                  : undefined,
              // disabled: true,
              fieldOptions: { required: false },
            })}
          </div>
          <div className="flex overflow-hidden w-full">
            {formField({
              displayName: "(E) FIN",
              internalName: "regCertFIN",

              className: "w-9/12 px-0 mr-1",
              fieldOptions: {
                required: "required",
                maxLength: { value: 17, message: "Must be exactly 17 chars." },
                minLength: { value: 17, message: "Must be exactly 17 chars." },
                pattern: {
                  message: "O, I, Q prohibited!",
                  value: /^[^OIQ]+$/,
                },
              },
            })}
            {formField({
              displayName: "(3) Check",
              internalName: "regCertFINPZ",
              className: "px-0 w-3/12",
              fieldOptions: {
                maxLength: { value: 1, message: "Must be exactly 1 digit." },
                minLength: { value: 1, message: "Must be exactly 1 digit." },
                pattern: {
                  message: "Single digit, or empty",
                  value: /^\d+$/,
                },
              },
            })}
          </div>
          {/* {formField({
            displayName: "HSN / TSN + PZ",
            internalName: "RegCertHSNTSNPZ",
            fieldOptions: {
              required: "required",
              maxLength: { value: 13, message: "Nicht max 13 Zeichen lang" },
              minLength: { value: 12, message: "Nicht min 12 Zeichen lang" },
            },
          })} */}
          {formField({
            displayName: "(A) Reg. Plate",
            internalName: "regCertRegPlate",
          })}
          {formField({
            displayName: "(C.1.1) Last name / Company",
            internalName: "regCertLastName",
          })}
          {formField({
            displayName: "(C.1.2) First name (if present)",
            internalName: "regCertFirstName",
            fieldOptions: {
              required: false,
            },
          })}
          {formField({
            displayName: "Straße + Hausnummer",
            internalName: "regCertStreet",
          })}
          <div className="flex overflow-hidden w-full">
            {formField({
              displayName: "PLZ",
              internalName: "regCertZIP",
              className: "mr-1 w-5/12",
              fieldOptions: {
                required: "required",
                pattern: {
                  message: "Ungültige PLZ",
                  value: /^([0]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{3}$/,
                },
              },
            })}
            {formField({
              displayName: "Stadt",
              internalName: "regCertCity",
            })}
          </div>
          <div className="flex items-start">
            {formField({
              displayName: "(I) Issue date",
              internalName: "regCertIssueDate",
              className: "mr-1",
              fieldOptions: {
                required: "required",
                pattern: {
                  message: "Invalid Date",
                  value: /^\d{1,2}[./-]\d{1,2}[./-]\d{4}$/,
                },
              },
            })}
            {formField({
              displayName: "(J) Car category",
              internalName: "regCertCarCategory",
              className: "mr-1 w-1/",
            })}
          </div>
          {formField({
            displayName: '(10) Fuel type (below "Elektro")',
            internalName: "antrieb",
            className: "mr-1 w-1/",
            fieldOptions: {
              required: "required",
              pattern: {
                message:
                  "Not a electric car? Remeber, that this field changed from 'Elektro' to '0004'.",
                value: /^0004$/i,
              },
            },
          })}

          {isMutError && (
            <p className="text-red-500 my-4">
              An error occurred. Error message:
              <br />
              {/* @ts-ignore */}
              {mutError?.data?.message
                ? // @ts-ignore
                  mutError?.data?.message
                : JSON.stringify(mutError)}
            </p>
          )}
          <input
            className="btn btn-primary w-full mt-4"
            type="submit"
            // disabled={submitEnabled}
            value={"Save"}
          />
        </form>
      </div>
    </div>
  );
};
