import React, { useState } from "react";
import { JsonForms } from "@jsonforms/react";
import { RouteComponentProps } from "@reach/router";
import { person } from "@jsonforms/examples";
import {
  materialRenderers,
  materialCells,
} from "@jsonforms/material-renderers";
import OverwriteInvoiceSchema from "../schema/OverwriteInvoice.json";
import {
  useApiPayoutOverwriteInvoiceMutation,
  useCustomerMutation,
} from "../state/adminApi";
import { DealManualDetails, PayoutDetails } from "../state/frontendTypes";

export const OverwriteInvoice: React.FC<RouteComponentProps<{}>> = ({
  ...props
}) => {
  const [
    getCustomer,
    {
      isLoading: isCustomerLoading,
      isError: isCustomerError,
      error: customerError,
      data: customerData,
    },
  ] = useCustomerMutation();

  const [
    doOverwrite,
    {
      isLoading: isOverwriteLoading,
      isError: isOverwriteError,
      error: overwriteError,
      data: overwriteData,
    },
  ] = useApiPayoutOverwriteInvoiceMutation();

  const uiSchema = {
    type: "VerticalLayout",
    elements: [
      { type: "Control", scope: "#/properties/newInvoiceBillingType" },
      {
        type: "Control",
        scope: "#/properties/bankDate",
        label:
          "BankDate (Wann ist Geld zurück gekommen (Rückberweisung/Rückbuchung)?, sonst leer)",
      },
      { type: "Control", scope: "#/properties/fullfillmentYear" },
      { type: "Control", scope: "#/properties/referralBonus" },
      {
        type: "Control",
        scope: "#/properties/dealDetails",
        label: "Deals",
        options: {
          elementLabelProp: "abc",
          detail: {
            type: "VerticalLayout",
            elements: [
              {
                type: "Control",
                scope: "#/properties/rateNet",
                label: "rateNet",
              },
              {
                type: "Control",
                scope: "#/properties/bonus",
                label: "Bonuses",
                options: {
                  detail: {
                    type: "VerticalLayout",
                    elements: [
                      {
                        type: "Control",
                        scope: "#/properties/name",
                        label: "Bonusname",
                      },
                      {
                        type: "Control",
                        scope: "#/properties/amount",
                        label: "Betrag",
                      },
                    ],
                  },
                },
              },
              {
                type: "Control",
                scope: "#/properties/regCertRegPlate",
                label: "Kennzeichen",
              },
              {
                type: "Control",
                scope: "#/properties/regCertFin",
                label: "FIN",
              },
              {
                type: "Control",
                scope: "#/properties/regCertFirstName",
                label: "Vorname",
              },
              {
                type: "Control",
                scope: "#/properties/regCertLastName",
                label: "Nachname / Firmenname",
              },
              {
                type: "Control",
                scope: "#/properties/dealId",
                label: "dealId",
              },
            ],
          },
        },
      },
      {
        type: "Control",
        scope: "#/properties/manualDetails",
        label: "Manuelle Posten",
        options: {
          elementLabelProp: "abc",
          detail: {
            type: "VerticalLayout",
            elements: [
              {
                type: "Control",
                scope: "#/properties/description",
                label: "description",
                options: {
                  multi: true,
                },
              },
              {
                type: "Control",
                scope: "#/properties/amountNet",
                label: "amountNet",
              },
            ],
          },
        },
      },
    ],
  };
  const [oldInvoiceNumber, setInvoiceNumber] = useState<string>();
  const [formData, setFormData] = useState<{
    newInvoiceBillingType:
      | "TextCorrection"
      | "OpenPayout"
      | "OpenPayoutAfterRefund"
      | "Cancelation";
    bankDate?: string;
    fullfillmentYear: string;
    referralBonus?: number;
    dealDetails: PayoutDetails[];
    manualDetails: DealManualDetails[];
    contactId: string;
    oldInvoiceNumber: string;
  }>({
    // @ts-ignore
    newInvoiceBillingType: "",
    fullfillmentYear: "",
    dealDetails: [] as PayoutDetails[],
    manualDetails: [] as DealManualDetails[],
  });
  const [errors, setErrors] = useState<any>();

  React.useEffect(() => {
    const payoutHistory = customerData?.payoutHistory
      ? customerData.payoutHistory
      : [];

    const oldInvoiceData = payoutHistory.find(
      (x) => x.billingData && x.billingData.invoiceNumber === oldInvoiceNumber
    );
    setFormData({
      ...formData,
      dealDetails: oldInvoiceData?.dealDetails
        ? oldInvoiceData?.dealDetails
        : [],
      manualDetails: oldInvoiceData?.manualDetails
        ? oldInvoiceData?.manualDetails
        : [],
      fullfillmentYear: oldInvoiceData?.billingData?.fullfillmentYear
        ? oldInvoiceData?.billingData?.fullfillmentYear
        : "",
      referralBonus: oldInvoiceData?.referralBonus
        ? oldInvoiceData?.referralBonus
        : undefined,
      oldInvoiceNumber: oldInvoiceNumber ? oldInvoiceNumber : "",
      contactId: oldInvoiceNumber?.split("-")[0]
        ? oldInvoiceNumber?.split("-")[0]
        : "",
    });
  }, [customerData, oldInvoiceNumber]);

  const submit = async () => {
    const contactId = oldInvoiceNumber?.split("-")[0];

    if (contactId && oldInvoiceNumber) {
      doOverwrite({
        ...formData,
        contactId,
        oldInvoiceNumber,
      });
    }
  };
  return (
    <>
      OverwriteInvoice
      <div className="max-w-2xl mx-auto mt-16">
        Gutschriftnummer eingeben:
        <div className="flex gap-x-1">
          <input
            className={`w-full input input-primary input-transparent text-lg `}
            value={oldInvoiceNumber}
            onChange={(event) => setInvoiceNumber(event.target.value)}
          />
          <button
            className="btn btn-primary"
            onClick={() => {
              if (oldInvoiceNumber) {
                getCustomer({ cid: oldInvoiceNumber.split("-")[0] });
              }
            }}
          >
            Start
          </button>
        </div>
        {isCustomerLoading && <div>Bitte warten...</div>}
        {customerData && (
          <>
            <JsonForms
              schema={OverwriteInvoiceSchema}
              uischema={uiSchema}
              data={formData}
              renderers={materialRenderers}
              cells={materialCells}
              onChange={({ data, errors }) => {
                setFormData(data);
                setErrors(errors);
              }}
            />
            <button
              className="btn btn-primary"
              onClick={() => submit()}
              disabled={errors && errors?.length > 0}
            >
              Korrektur erstellen
            </button>
          </>
        )}
        {/* {errors && errors.length > 0 && JSON.stringify(errors)} */}
        {isOverwriteLoading && <div>Bitte warten...</div>}
        {isOverwriteError && <div>{JSON.stringify(overwriteError)}</div>}
        {overwriteData && (
          <div>
            <a
              href={overwriteData.newPayoutLink}
              target="_blank"
              rel="noopener noreferrer"
              className="text-extrabold underline"
            >
              Temprärer Link zur neuen Gutschrift
            </a>
            <br />
            <a
              href={overwriteData.stornoPayoutLink}
              target="_blank"
              rel="noopener noreferrer"
              className="text-extrabold underline"
            >
              Temprärer Link zur Stornogutschrift
            </a>
          </div>
        )}
      </div>
    </>
  );
};

export default OverwriteInvoice;
