import React, { useState } from "react";
import { JsonForms } from "@jsonforms/react";
import { RouteComponentProps } from "@reach/router";
import { person } from "@jsonforms/examples";
import {
  materialRenderers,
  materialCells,
} from "@jsonforms/material-renderers";
import ManualInvoiceSchema from "../schema/ManualInvoice.json";
// import { generateDefaultUISchema, generateJsonSchema } from "@jsonforms/core";
import { useApiPayoutManualInvoiceMutation } from "../state/adminApi";

export const ManualInvoice: React.FC<RouteComponentProps<{}>> = ({
  ...props
}) => {
  const [
    createManualInvoice,
    {
      isLoading: isMutLoading,
      isError: isMutError,
      error: mutError,
      data: mutData,
    },
  ] = useApiPayoutManualInvoiceMutation();
  const initialData = {
    contactId: "1750751",
    invoiceItems: [
      {
        description: "Beispiel Buchungstext",
        amountNet: 1,
      },
    ],
  };
  const uiSchema = {
    type: "VerticalLayout",
    elements: [
      { type: "Control", scope: "#/properties/contactId" },
      {
        type: "Control",
        scope: "#/properties/invoiceItems",
        label: "Gutschriftposten",
        options: {
          showSortButtons: true,
          detail: {
            type: "VerticalLayout",
            elements: [
              {
                type: "Control",
                scope: "#/properties/description",
                label: "Gutschrifttext",
              },
              {
                type: "Control",
                scope: "#/properties/amountNet",
                label: "Netto Betrag (brutto wird bei Firmenkunden berechnet)",
              },
            ],
          },
        },
      },
    ],
  };
  // console.log(JSON.stringify(generateDefaultUISchema(ManualInvoiceSchema)));
  const [data, setData] = useState(initialData);
  const [errors, setErrors] = useState<any>();
  // console.log(errors);
  console.log(mutData);
  return (
    <div className="w-full">
      Manuell eine Gutschrift erstellen
      <div className="max-w-2xl mx-auto">
        <JsonForms
          schema={ManualInvoiceSchema}
          uischema={uiSchema}
          data={data}
          renderers={materialRenderers}
          cells={materialCells}
          onChange={({ data, errors }) => {
            setData(data);
            setErrors(errors);
          }}
        />
      </div>
      {/* {errors && errors.length > 0 && JSON.stringify(errors)} */}
      <div className="max-w-md mx-auto mx-auto">
        <button
          className={`btn btn-primary mx-auto block`}
          disabled={errors && errors?.length > 0}
          onClick={() => createManualInvoice(data)}
        >
          Rechnung erstellen
        </button>
        {mutData && (
          <p>
            Rechnung erfolgreich erstellt. Diese wird mit der nächsten
            Auszahlungsrunde ausgezahlt. Wenn aktuell kein Batch ansteht, Lenz
            bescheid sagen damit zeitnah ausgezahlt wird.
            <br />
            <a
              href={mutData.link}
              target="_blank"
              rel="noopener noreferrer"
              className="text-extrabold underline"
            >
              Temprärer Link zur Rechnung
            </a>
          </p>
        )}
        {mutError && JSON.stringify(mutError)}
      </div>
    </div>
  );
};

export default ManualInvoice;
