// source https://kryptografie.de/kryptografie/chiffre/fin.htm
export const getFinPruefziffer = (fin: string): number | false => {
  if (fin.length !== 17) return false;
  let sum = 0;
  let currWeight = 9;

  for (let i = 0; i < fin.length; i++) {
    const currNum = isNumeric(fin[i]) ? parseInt(fin[i]) : char2Num(fin[i]);
    sum += currNum * currWeight;
    if (currWeight > 2) currWeight -= 1;
    else currWeight = 10;
  }
  console.log("sum", sum);
  return sum % 11;
};

const char2Num = (c: string): number => {
  switch (c) {
    case "A":
    case "J":
      return 1;
    case "B":
    case "K":
    case "S":
      return 2;
    case "C":
    case "L":
    case "T":
      return 3;
    case "D":
    case "M":
    case "U":
      return 4;
    case "E":
    case "N":
    case "V":
      return 5;
    case "F":
    case "W":
      return 6;
    case "G":
    case "P":
    case "X":
      return 7;
    case "H":
    case "Q":
    case "Y":
      return 8;
    case "I":
    case "R":
    case "Z":
      return 9;
    case "O":
      return 0;
    default:
      return 1;
  }
};
export const isNumeric = (str: any) => {
  if (typeof str != "string") return false; // we only process strings!
  return (
    // @ts-ignore
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
};
