import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { isUser, setAuthState } from "./authSlice";
import { Customer } from "./frontendTypes";
import netlifyAuth from "./netlifyAuth";
import { RootState } from "./reducers";
import {
  ApiPayoutManualInvoice,
  ApiPayoutOverwriteInvoice,
  ChangesRequiredType,
  DealData,
} from "./types";

const baseQuery = fetchBaseQuery({
  baseUrl: "/.netlify/functions/",
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const authState = (getState() as RootState).auth;
    if (isUser(authState)) {
      const token = authState.token;
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});
const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  console.log(result);
  if (result.error && result.error.status === 401) {
    api.dispatch(setAuthState("LoggedOut"));
    netlifyAuth.signout(() => {});
  }
  return result;
};
export const adminApi = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Customer"],
  endpoints: (builder) => ({
    getVerifiedDeal: builder.mutation<
      DealData,
      { dealId?: string; stage: string }
    >({
      query: ({ dealId, stage }) =>
        dealId
          ? `getVerifiedDeal/?dealId=${dealId}&stage=${stage}`
          : `getVerifiedDeal?stage=${stage}`,
    }),
    saveVerifiedDeal: builder.mutation<{}, DealData>({
      query: (body) => {
        return {
          url: `saveVerifiedDeal`,
          method: "POST",
          body,
        };
      },
    }),
    apiPayoutManualInvoice: builder.mutation<
      { link: string },
      ApiPayoutManualInvoice
    >({
      query: (body) => {
        return {
          url: `frontendApi`,
          method: "POST",
          body: { ...body, endpointName: "apiPayoutManualInvoice" },
        };
      },
    }),
    customer: builder.mutation<Customer, { cid: string }>({
      query: (body) => {
        return {
          url: `frontendApi`,
          method: "POST",
          body: { ...body, endpointName: "apiCustomer" },
        };
      },
    }),
    apiPayoutOverwriteInvoice: builder.mutation<
      { newPayoutLink: string; stornoPayoutLink: string },
      ApiPayoutOverwriteInvoice
    >({
      query: (body) => {
        return {
          url: `frontendApi`,
          method: "POST",
          body: { ...body, endpointName: "apiPayoutOverwriteInvoice" },
        };
      },
    }),
    setUBAKompliziert: builder.mutation<
      {},
      { dealId: string; reason?: ChangesRequiredType }
    >({
      query: (body) => {
        return {
          url: `setUBAKompliziert`,
          method: "POST",
          body,
        };
      },
    }),
  }),
});

export const {
  useGetVerifiedDealMutation,
  useSaveVerifiedDealMutation,
  useSetUBAKompliziertMutation,
  useApiPayoutManualInvoiceMutation,
  useCustomerMutation,
  useApiPayoutOverwriteInvoiceMutation,
} = adminApi;
