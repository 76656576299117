import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import React from "react";
import netlifyAuth from "./netlifyAuth";
import { RootState, useAppDispatch } from "./reducers";
// import type { RootState } from "../state/createStore"

export type AuthState = LoggedOut | Waiting | User;

export type LoggedOut = "LoggedOut";
export type Waiting = "Waiting";
export type Customer = "customer";
export type Admin = "admin";

export type User = {
  token: string;
  email: string;
};

const slice = createSlice({
  name: "auth",
  initialState: "LoggedOut" as AuthState,
  reducers: {
    setAuthState: (state, action: PayloadAction<AuthState>) => {
      if (isUser(action.payload)) {
        window.localStorage.setItem("user", JSON.stringify(action.payload));
      } else window.localStorage.removeItem("user");
      return action.payload;
    },
  },
});

export const { setAuthState } = slice.actions;

export default slice.reducer;

export const checkIsLoggedIn = (state: RootState): boolean => {
  return (state.auth as User).token !== undefined;
};
export const getEmail = (state: RootState): string | null => {
  return (state.auth as User).email;
};
export const isUser = (auth: AuthState): auth is User => {
  return (auth as User).token !== undefined;
};

export const AuthWrapper: React.FC = ({ children }) => {
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    netlifyAuth.initialize((user) => {
      if (user)
        dispatch(
          setAuthState({ token: user.token.access_token, email: user.email })
        );
    });
  }, []);
  React.useEffect(() => {
    if (typeof window !== "undefined" && window.localStorage) {
      const gotrueUser = window.localStorage.getItem("gotrue.user");
      if (typeof gotrueUser === "string")
        dispatch(
          setAuthState({
            token: JSON.parse(gotrueUser).token.access_token,
            email: JSON.parse(gotrueUser).email,
          })
        );
    }
  }, []);
  return <>{children}</>;
};
