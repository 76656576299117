import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSync,
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
  faRandom,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";

import "./ImageArea.css";
import { DealDataForm } from "../../features/DealDataForm/DealDataForm";
import { ImageOcr } from "../../features/imageOcr/ImageOcr";
import { RouteComponentProps } from "@reach/router";
import {
  useGetVerifiedDealMutation,
  useSetUBAKompliziertMutation,
} from "../../state/adminApi";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Popup } from "../../features/Popup/Popup";
import { changesRequiredTypes, DealStage } from "../../state/types";

export const ImageArea: React.FC<RouteComponentProps<{}>> = ({ ...props }) => {
  const [getDealData, { data: dealData, error, isLoading, isError }] =
    useGetVerifiedDealMutation(undefined);
  const [setDealKompliziert, {}] = useSetUBAKompliziertMutation();

  const [dealId, setDealId] = useState<string>();
  const [userDealCount, setUserDealCount] = useState<number | null>();
  const [imageIdx, setImageIdx] = useState<number>(0);
  const [ocrSelectedVal, setOcrSelectedVal] = useState("");
  const [ocrJsonDownloaded, setOcrJsonDownloaded] = useState(undefined);
  const [showOcr, setShowOcr] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [canvasRotation, setCanvasRotation] = useState<0 | 90 | 180 | 270>(0);

  React.useEffect(() => {
    const fetchNewOcr = async (url: string) => {
      const fetchResponse = await fetch(url);
      const newOcrJson = await fetchResponse.json();
      setOcrJsonDownloaded(newOcrJson);
      setShowOcr(true);
    };
    setOcrJsonDownloaded(undefined);
    setShowOcr(false);
    setCanvasRotation(0);
    if (
      dealData?.regCertFiles &&
      dealData?.regCertFiles[imageIdx] &&
      dealData?.regCertFiles[imageIdx].ocrUrl
    ) {
      fetchNewOcr(dealData?.regCertFiles[imageIdx].ocrUrl);
    }
  }, [imageIdx, dealId, dealData?.regCertFiles]);

  const getNewDeal = (dealId?: string) => {
    getDealData({
      dealId,
      stage: DealStage.Signed,
    })
      .unwrap()
      .then((data) => {
        setDealId(data.dealId);
        if (data.userDealCount) setUserDealCount(data.userDealCount);
        setOcrSelectedVal("");
        setImageIdx(0);
        setCurrentPage(0);
      })
      .catch(() => {});
  };

  const statusMsg = (msg: string | React.ReactNode, className?: string) => (
    <div
      className={
        "shadow rounded-md px-4 py-6 m-auto max-w-md mt-12 text-center font-bold " +
        className
      }
    >
      {msg}
    </div>
  );

  return (
    <>
      <div className="w-full h-24">
        <div className="w-full h-14 fixed top-0 left-0 ">
          <div
            id="navbar"
            className="w-auto h-14 mr-84 bg-white shadow-md mb-2 flex justify-start items-center gap-2 rounded-md border border-primary"
          >
            <button
              className="btn btn-primary"
              onClick={() => {
                setCanvasRotation(
                  // @ts-ignore
                  canvasRotation === 270 ? 0 : canvasRotation + 90
                );
              }}
            >
              <FontAwesomeIcon icon={faSync} size="lg" />
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                setShowOcr(!showOcr);
              }}
            >
              <span className="mr-2">OCR</span>
              <FontAwesomeIcon icon={faEyeSlash} size="lg" />
            </button>
            {/* <button
          className="btn btn-primary"
          onClick={() => {
            setCanvasOcrRotation(
              // @ts-ignore
              canvasOcrRotation === 270 ? 0 : canvasOcrRotation + 90
            );
          }}
        >
          <span className="mr-2">OCR</span>
          <FontAwesomeIcon icon={faSync} size="lg" />
        </button> */}

            <div className="flex">
              <div className="mr-1">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    getNewDeal();
                  }}
                >
                  <FontAwesomeIcon icon={faRandom} size="lg" />
                </button>
              </div>
              <input
                className={`w-full input input-primary input-transparent text-lg `}
                value={dealId}
                onChange={(event) => setDealId(event.target.value)}
              />
              <div className="ml-1">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    getNewDeal(dealId);
                  }}
                >
                  <FontAwesomeIcon icon={faArrowAltCircleRight} size="lg" />
                </button>
              </div>
            </div>
            {dealData && (
              <div className="grid grid-rows-2 grid-flow-col gap-x-3 btn btn-primary cursor-default text-left">
                {dealData && dealData.remainingDeals && (
                  <>
                    <div>Open:</div>
                    <div>{dealData.remainingDeals}! 🚀</div>
                  </>
                )}
                {dealData && dealData.userDealCount && (
                  <>
                    <div>Finished:</div>
                    <div>{dealData.userDealCount}! 🎉</div>
                  </>
                )}
              </div>
            )}
            {/* <button
            className="btn btn-primary mr-4"
            onClick={async () => {
              if (imageUrl) {
                const newOcrJson = await getOcr(imageUrl);
                setCroppedOcrJson(newOcrJson);
              }
            }}
          >
            <FontAwesomeIcon icon={faQrcode} size="lg" />
          </button> */}
            {dealId && (
              <div className="ml-1">
                <button
                  className="btn btn-primary bg-red-500 border-red-500"
                  onClick={() => {
                    confirmAlert({
                      customUI: ({ onClose }) => (
                        <Popup
                          // confirm={{ text: "Submit", event: () => {} }}
                          abort={{ text: "Cancel", event: onClose }}
                          content={
                            <>
                              <select
                                className="select select-bordered select-primary w-full max-w-xs"
                                onChange={(event) => {
                                  console.log(event.target.value);
                                  // setKompliziertReason(name);
                                  setDealKompliziert({
                                    dealId: dealId,
                                    // @ts-ignore
                                    reason: event.target.value
                                      ? event.target.value
                                      : undefined,
                                  })
                                    .unwrap()
                                    .then(() => {
                                      getNewDeal();
                                      onClose();
                                    });
                                }}
                                defaultValue={"Select a reason"}
                              >
                                <option disabled={true}>Select a reason</option>
                                {changesRequiredTypes.map((name) => (
                                  <option>{name}</option>
                                ))}
                              </select>
                            </>
                          }
                        />
                      ),
                    });
                  }}
                >
                  Complicated!
                </button>
              </div>
            )}
            {dealData && (
              <>
                {dealData.companyVerified && (
                  <span className="px-1 py-1 rounded-md font-bold bg-green-800 text-white">
                    Company Verified!
                  </span>
                )}
                <div className="ml-1">
                  <a
                    href={
                      "https://app-eu1.hubspot.com/contacts/25109860/deal/" +
                      dealData.dealId
                    }
                    rel="noreferrer"
                    target="_blank"
                  >
                    <button className="btn btn-primary">HS</button>
                  </a>
                </div>

                <ul className="menu px-0 m-0 h-full shadow-lg bg-base-100 rounded-box horizontal bg-brand-yellow text-white rounded-md items-center">
                  <li
                    onClick={() => {
                      setImageIdx(
                        imageIdx === 0
                          ? dealData.regCertFiles.length - 1
                          : imageIdx - 1
                      );
                      setCurrentPage(currentPage + 1);
                    }}
                  >
                    <a>
                      <FontAwesomeIcon icon={faArrowAltCircleLeft} size="lg" />
                    </a>
                  </li>
                  <li className="">
                    Bild {imageIdx + 1} von {dealData.regCertFiles.length}
                  </li>
                  <li
                    onClick={() => {
                      setImageIdx(
                        imageIdx < dealData.regCertFiles.length - 1
                          ? imageIdx + 1
                          : 0
                      );
                      setCurrentPage(currentPage + 1);
                    }}
                  >
                    <a>
                      <FontAwesomeIcon icon={faArrowAltCircleRight} size="lg" />
                    </a>
                  </li>
                </ul>
              </>
            )}
          </div>
        </div>
      </div>
      {isLoading ? (
        statusMsg("Loading")
      ) : isError ? (
        JSON.stringify(error).includes("property 'email' of undefined") ? (
          statusMsg("Please Login!" + JSON.stringify(error))
        ) : JSON.stringify(error).includes("Task timed out") ? (
          statusMsg("The request timed out. Usually a reload fixes this.")
        ) : (
          statusMsg(
            "An error ocurred. Plase make a bug report in Notion." +
              JSON.stringify(error)
          )
        )
      ) : !dealData ? (
        statusMsg("No Deal has been loaded yet.")
      ) : dealData && dealData.regCertFiles.length < 1 ? (
        statusMsg(
          // " Zu diesem Deal wurden keine Bilder gefunden. Sind die Dateinamen in Hubspot ggf nicht korrekt aus AWS übernommen?"
          "No Images were found for this deal. Maybe the filenames are incorrect in HubSpot? Please put this deal on complicated."
        )
      ) : (
        <>
          <div className="m-0 w-full flex">
            <div className="w-full rounded shadow-md p-1">
              {dealData.regCertFiles.length > 2 &&
                statusMsg(
                  <>
                    Warning. <br />
                    More than 2 Images were loaded.
                    <br />
                    <span className="font-bold text-red-600">
                      Please make sure that these do not show multiple
                      registration certificates!
                    </span>
                    <br />
                    If this is the case, hit 'complicated'. <br />
                  </>,
                  "text-amber-700"
                )}
              <ImageOcr
                url={
                  dealData.regCertFiles && dealData.regCertFiles[imageIdx]
                    ? dealData.regCertFiles[imageIdx].signedUrl
                    : ""
                }
                json={ocrJsonDownloaded}
                onSelect={(word) => {
                  setOcrSelectedVal(word);
                  setTimeout(() => setOcrSelectedVal(""), 100);
                }}
                canvasRef={canvasRef}
                rotate={canvasRotation}
                showOcr={showOcr}
              />
              <div className="w-full h-24">
                <div className="w-full h-24  fixed bottom-0 left-0 ">
                  <div className="w-auto h-24 mr-84 bg-white shadow-md rounded-md border border-primary">
                    {/* <h2 className="card-title">Account Data</h2> */}

                    <div className="overflow-x-auto">
                      <table className="table w-full table-compact">
                        <thead>
                          <tr>
                            <th>WhitelabelPartner</th>
                            {/* <th>E-Mail</th> */}
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Company Name</th>
                            <th>Rate</th>
                            <th>remcontractyears</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <input
                                readOnly={true}
                                type="text"
                                value={dealData.whitelabelPartner}
                                className="input input-primary input-bordered pr-0"
                              ></input>
                            </td>
                            {/* <td>
                                <input
                                  readOnly={true}
                                  type="text"
                                  value={dealData.email}
                                  className="input input-primary input-bordered pr-0"
                                ></input>
                              </td> */}
                            <td>
                              <input
                                readOnly={true}
                                type="text"
                                value={dealData.firstName}
                                className="input input-primary input-bordered pr-0"
                              ></input>
                            </td>
                            <td>
                              <input
                                readOnly={true}
                                type="text"
                                value={dealData.lastName}
                                className="input input-primary input-bordered pr-0"
                              ></input>
                            </td>
                            <td>
                              <input
                                readOnly={true}
                                type="text"
                                value={dealData.company}
                                className="input input-primary input-bordered pr-0"
                              ></input>
                            </td>
                            <td>
                              <input
                                readOnly={true}
                                type="text"
                                value={dealData.rate}
                                className="input input-primary input-bordered pr-0"
                              ></input>
                            </td>
                            <td>
                              <input
                                readOnly={true}
                                type="text"
                                value={dealData.remcontractyears}
                                className="input input-primary input-bordered pr-0"
                              ></input>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <DealDataForm
              ocrSelectedVal={ocrSelectedVal}
              dealData={dealData}
              imageIdx={imageIdx}
              getNetDeal={getNewDeal}
              numOfPageChanges={currentPage}
            />
          </div>
        </>
      )}
    </>
  );
};

export default ImageArea;
