import React, { useState } from "react";
import { JsonForms } from "@jsonforms/react";
import { RouteComponentProps } from "@reach/router";
import { person } from "@jsonforms/examples";
import {
  materialRenderers,
  materialCells,
} from "@jsonforms/material-renderers";
import PayoutHistorySchema from "../schema/PayoutHistory.json";
import { generateDefaultUISchema, generateJsonSchema } from "@jsonforms/core";
import { CopyToClipboard } from "react-copy-to-clipboard";

export const PayoutHistoryAdmin: React.FC<RouteComponentProps<{}>> = ({
  ...props
}) => {
  const initialData = [
    {
      payoutStage: "3.PayoutEmailDone",
      billingData: {
        invoiceNumber: "123456-1650100",
        invoiceDate: "2022-04-17T00:00:00.000Z",
        fullfillmentYear: "2022",
        contactId: "123456",
        firstName: "Max",
        lastName: "Mustermann",
        bankHolderName: "Max Mustermann",
        bankIban: "DE65 1001 1001 2624 6922 22",
      },
      taxFreeMode: false,
      totalNet: 270,
      ratesNet: 270,
      dealDetails: [
        {
          rateNet: 270,
          bonus: [],
          regCertRegPlate: "SON LM89E",
          regCertFin: "5YJ3E7EB8LF757591",
          regCertFirstName: "MARCELLO",
          regCertLastName: "LANGGUTH ",
          dealId: "4062686676",
        },
      ],
      manualDetails: [],
    },
  ];

  const uiSchema = {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#",
        label: "Payouts",
        options: {
          elementLabelProp: "abc",
          detail: {
            type: "VerticalLayout",
            elements: [
              {
                type: "Control",
                scope: "#/properties/payoutStage",
                label: "PayoutStage",
              },
              {
                type: "Control",
                scope: "#/properties/bankDate",
                label: "bankDate",
              },
              {
                type: "Control",
                scope: "#/properties/taxFreeMode",
                label: "Steueroptimiert (TaxFreeMode)",
              },
              {
                type: "Control",
                scope: "#/properties/billingData",
                label: "BillingData",
                options: {
                  detail: {
                    type: "VerticalLayout",
                    elements: [
                      {
                        type: "Control",
                        scope: "#/properties/invoiceType",
                        options: {
                          detail: {
                            type: "VerticalLayout",
                            elements: [
                              {
                                type: "Control",
                                scope: "#/properties/invoiceTypeName",
                                label: "invoiceTypeName",
                              },
                            ],
                          },
                        },
                      },
                      {
                        type: "Control",
                        scope: "#/properties/invoiceNumber",
                        label: "invoiceNumber",
                      },
                      {
                        type: "Control",
                        scope: "#/properties/invoiceDate",
                        label: "invoiceDate",
                      },
                      {
                        type: "Control",
                        scope: "#/properties/fullfillmentYear",
                        label: "fullfillmentYear",
                      },
                      {
                        type: "Group",
                        label: "Kotaktdaten",
                        elements: [
                          {
                            type: "Control",
                            scope: "#/properties/contactId",
                            label: "contactId",
                          },
                          {
                            type: "HorizontalLayout",
                            elements: [
                              {
                                type: "Control",
                                scope: "#/properties/firstName",
                                label: "firstName",
                              },
                              {
                                type: "Control",
                                scope: "#/properties/lastName",
                                label: "lastName",
                              },
                            ],
                          },
                          {
                            type: "HorizontalLayout",
                            elements: [
                              {
                                type: "Control",
                                scope: "#/properties/bankHolderName",
                                label: "bankHolderName",
                              },
                              {
                                type: "Control",
                                scope: "#/properties/bankIban",
                                label: "bankIban",
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                },
              },
              {
                type: "Control",
                scope: "#/properties/dealDetails",
                label: "Deals",
                options: {
                  elementLabelProp: "abc",
                  detail: {
                    type: "VerticalLayout",
                    elements: [
                      {
                        type: "Control",
                        scope: "#/properties/rateNet",
                        label: "rateNet",
                      },
                      {
                        type: "Control",
                        scope: "#/properties/bonus",
                        label: "Bonuses",
                        options: {
                          detail: {
                            type: "VerticalLayout",
                            elements: [
                              {
                                type: "Control",
                                scope: "#/properties/name",
                                label: "name",
                              },
                              {
                                type: "Control",
                                scope: "#/properties/amount",
                                label: "amount",
                              },
                            ],
                          },
                        },
                      },
                      {
                        type: "Control",
                        scope: "#/properties/regCertRegPlate",
                        label: "regCertRegPlate",
                      },
                      {
                        type: "Control",
                        scope: "#/properties/regCertFin",
                        label: "regCertFin",
                      },
                      {
                        type: "Control",
                        scope: "#/properties/regCertFirstName",
                        label: "regCertFirstName",
                      },
                      {
                        type: "Control",
                        scope: "#/properties/regCertLastName",
                        label: "Nachname / Firmenname",
                      },
                      {
                        type: "Control",
                        scope: "#/properties/dealId",
                        label: "dealId",
                      },
                    ],
                  },
                },
              },
              {
                type: "Control",
                scope: "#/properties/manualDetails",
                label: "Manuelle Posten",
                options: {
                  elementLabelProp: "abc",
                  detail: {
                    type: "VerticalLayout",
                    elements: [
                      {
                        type: "Control",
                        scope: "#/properties/description",
                        label: "description",
                        options: {
                          multi: true,
                        },
                      },
                      {
                        type: "Control",
                        scope: "#/properties/amountNet",
                        label: "amountNet",
                      },
                    ],
                  },
                },
              },
              {
                type: "Group",
                label: "Raten",
                elements: [
                  {
                    type: "Control",
                    scope: "#/properties/ratesNet",
                    label: "Raten (Netto)",
                    options: {
                      readOnly: true,
                    },
                  },
                  {
                    type: "Control",
                    scope: "#/properties/oneTimeBonus",
                    label: "Einmaliger Bonus (Netto)",
                    options: {
                      readOnly: true,
                    },
                  },
                  {
                    type: "Control",
                    scope: "#/properties/totalNet",
                    label: "Gesamtbetrag (Netto)",
                    options: {
                      readOnly: true,
                    },
                  },
                ],
              },
            ],
          },
        },
      },
    ],
  };
  console.log(JSON.stringify(generateDefaultUISchema(PayoutHistorySchema)));
  const [data, setData] = useState(initialData);
  return (
    <>
      PayoutHistoryAdmin
      <div className="max-w-2xl mx-auto mt-16">
        <p>
          Füge den Inhalt des PayoutHistory Feldes hier ein, um es zu
          bearbeiten. Kopiere die geänderte Version mit dem Button in deine
          Zwischenablage und speichere es wieder in HubSpot.
        </p>
        <div className="flex gap-x-1">
          <input
            className={`w-full input input-primary input-transparent text-lg `}
            value={JSON.stringify(data)}
            onChange={(event) => setData(JSON.parse(event.target.value))}
          />
          <CopyToClipboard text={JSON.stringify(data)}>
            <button className="btn btn-primary">In Zwischenablage</button>
          </CopyToClipboard>
        </div>
        <JsonForms
          schema={PayoutHistorySchema}
          uischema={uiSchema}
          data={data}
          renderers={materialRenderers}
          cells={materialCells}
          onChange={({ data }) => setData(data)}
        />
      </div>
    </>
  );
};

export default PayoutHistoryAdmin;
