export type Point = { x: number; y: number };

export function getCursorPosition(
  canvas: HTMLCanvasElement,
  event: MouseEvent
) {
  const rect = canvas.getBoundingClientRect();
  const x = event.clientX - rect.left;
  const y = event.clientY - rect.top;
  return { x, y };
}

export function getAbsCursorPosition(
  // as canvas is responsive, but json is to original image size
  canvas: HTMLCanvasElement,
  scaler: number,
  event: MouseEvent
) {
  const rect = canvas.getBoundingClientRect();
  const x = (event.clientX - rect.left) * scaler;
  const y = (event.clientY - rect.top) * scaler;
  return { x, y };
}
export function getRelativeCursorPosition(
  canvas: HTMLCanvasElement,
  scaler: number,
  event: MouseEvent
) {
  const rect = canvas.getBoundingClientRect();
  const xPercent = ((event.clientX - rect.left) * scaler) / canvas.width;
  const yPercent = ((event.clientY - rect.top) * scaler) / canvas.height;
  return { xPercent, yPercent };
}

export function colorFromConfidence(v: number) {
  // Produces a bounded number from Green to Red
  const hue = Math.floor(v * 120);
  // HSL stands for Hue / Saturation / Lightness. Look it up for better color control.
  return `hsl(${hue},100%,50%)`;
}

export function isPointInsideVertices(point: Point, vs: Point[]) {
  var x = point.x,
    y = point.y;
  var inside = false;
  for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    var xi = vs[i].x,
      yi = vs[i].y;
    var xj = vs[j].x,
      yj = vs[j].y;

    var intersect =
      yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }
  return inside;
}

export function drawSquareBasedOnVertices(
  ctx: CanvasRenderingContext2D,
  vertices: Point[],
  color: string,
  offset: [number, number]
) {
  ctx.beginPath();
  // Skip first vertice as start position
  ctx.moveTo(vertices[0].x + offset[0], vertices[0].y + offset[1]);
  ctx.lineWidth = 3;
  ctx.strokeStyle = color;
  for (const point of vertices.slice(1)) {
    ctx.lineTo(point.x + offset[0], point.y + offset[1]);
  }
  ctx.lineTo(vertices[0].x + offset[0], vertices[0].y + offset[1]);
  ctx.stroke();
}

export function processGoogleAPIJson(ocrJson: any) {
  if (ocrJson?.responses[0]?.textAnnotations) {
    const blocks = ocrJson.responses[0].textAnnotations.slice(1);
    const hierarchy = ocrJson.responses[0].fullTextAnnotation;
    const allBlocks = hierarchy.pages.reduce(
      (acc: any, page: any) =>
        acc.concat(page.blocks.filter((block: any) => block.paragraphs)),
      []
    );
    const allParagraphs = allBlocks.reduce(
      (acc: any, block: any) => acc.concat(block.paragraphs),
      []
    );
    const allWords = allParagraphs.reduce(
      (acc: any, paragraph: any) => acc.concat(paragraph.words),
      []
    );
    return { blocks, allWords };
  } else return { blocks: [], allWords: [] };
}

const MIN_WORD_LENGTH_FOR_ROTATION_INFERENCE = 5;
const mean = (arr: number[]) => arr.reduce((acc, a) => acc + a, 0) / arr.length;

export function inferRotationOfOcrJson(words: any) {
  for (const word of words) {
    if (word.symbols.length < MIN_WORD_LENGTH_FOR_ROTATION_INFERENCE) continue;
    const firstChar = word.symbols[0];
    const lastChar = word.symbols[word.symbols.length - 1];
    const firstCharCenter = {
      x: mean(firstChar.boundingBox.vertices.map((v: any) => v.x)),
      y: mean(firstChar.boundingBox.vertices.map((v: any) => v.y)),
    };
    const lastCharCenter = {
      x: mean(lastChar.boundingBox.vertices.map((v: any) => v.x)),
      y: mean(lastChar.boundingBox.vertices.map((v: any) => v.y)),
    };

    if (
      Math.abs(firstCharCenter.x - lastCharCenter.x) >
      Math.abs(firstCharCenter.y - lastCharCenter.y)
    ) {
      return firstCharCenter.x <= lastCharCenter.x ? 0 : 180;
    } else {
      return firstCharCenter.y <= lastCharCenter.y ? 90 : 270;
    }
  }
  // throw new Error("Could not detect rotation of OCR");
  console.error("Could not detect rotation of OCR");
  return 0;
}

// Get JSON
// Infer JSON rotation
// Wir wissen ob hochformat oder nicht
// Bild drehen falls hoch/querformat falsch
// Nur noch upside/down

// On click
//    get relative position on canvas
//      => 50%, 70%
//    => projezieren wir das auf das JSON
//        => also evt. drehen
//    => absolute position auf Bild
//        => 500px, 700px

// x o x x
// x x x p
// x x x x
// x u x x
